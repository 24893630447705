import styled from 'styled-components';
import { Link as _Link } from 'gatsby';
import MEDIA from 'helpers/mediaTemplates';
import { accent, primaryFont, secondaryFont, black } from 'constants/theme';

export const Container = styled.section`
  margin: 0 auto;
  max-width: 1200px;
`;

export const List = styled.ul`
  margin: 1rem 2rem;
`;

export const ListItem = styled.li`
  margin: 2rem 0;
  box-shadow: 0px 2px 5px 2px rgba(222, 219, 219, 0.5);
`;

export const Link = styled(_Link)`
  text-decoration: none;
`;

export const Title = styled.h3`
  color: ${black};
  font-size: 3rem;
  margin-bottom: 2rem;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  border: none;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
  `}
`;

export const Content = styled.div`
  flex: 1;
  ${({ isImage }) =>
    isImage
      ? `
    padding: 2rem;
  `
      : `
    padding: 2rem;
  `}

  ${MEDIA.MIN_TABLET`
    ${({ isImage }) =>
      isImage
        ? ''
        : `
      padding: 2rem 4rem;
    `}
  `}
`;

export const Description = styled.p`
  color: ${black};
  font-family: ${secondaryFont};
  line-height: 1.5;
`;

export const ReadMoreButton = styled.div`
  display: inline-block;
  background-color: ${accent};
  color: #222222;
  font-weight: 400;
  line-height: 1.3rem;
  font-size: 1.6rem;
  padding: 2.3rem 3rem 2.3rem;
  font-family: ${primaryFont};
  text-decoration: none;
  &:hover,
  &:focus {
    color: white;
  }
`;
