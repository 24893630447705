import React from 'react';
import {
  Container,
  List,
  ListItem,
  Link,
  Title,
  Block,
  Content,
  Description,
  ReadMoreButton,
} from './callToActionBlocks.css';
import Img from 'gatsby-image';

const CallToActionBlocks = ({ blocks }) => {
  return (
    <Container>
      <List>
        {blocks.map((block, index) => {
          return (
            <ListItem key={index}>
              <Block>
                <Content isImage>
                  <Img
                    fluid={block.image ? block.image.childImageSharp.fluid : {}}
                    alt={''}
                  />
                </Content>
                <Content>
                  <Title>{block.title}</Title>
                  <Description>{block.description}</Description>
                  <div>
                    <Link to={block.link}>
                      <ReadMoreButton>Zobacz więcej</ReadMoreButton>
                    </Link>
                  </div>
                </Content>
              </Block>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default CallToActionBlocks;
