import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import Hero from 'components/hero';
import CallToActionBlocks from '../components/callToActionBlocks/callToActionBlocks';

const Index = ({ data }) => {
  const { content, heroImage, callToActionBlocks } = data.homeJson;
  const {
    siteMetadata: { siteTitle },
  } = data.site;
  return (
    <Layout>
      <Hero
        title={`${siteTitle} - ${content.childMarkdownRemark.rawMarkdownBody}`}
        image={heroImage}
      />
      <CallToActionBlocks blocks={callToActionBlocks} />
      <div style={{ height: '10vh' }} />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    site {
      siteMetadata {
        siteTitle
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      heroImage {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      callToActionBlocks {
        link
        title
        image {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
      }
    }
  }
`;
